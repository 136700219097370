<template>
  <div>
    <!-- picF7C3E94D-0537-4266-8A56-C2D3A85ABF76.jpg -->
    <img class="rounded img-large" src="">
    <p>
      <b>Chicago, USA</b><br>
      <b>June 20-22, 2008</b>
      <br>
      The LEGO Universe team will share sneak peaks of the new LEGO Universe logo and game with LEGO fans (up 8,000 of them!) at the BrickWorld event this June in Chicago. Don't worry if you can't make it to the event, because we'll keep you posted on all the breaking news right here at LEGOUniverse.com!
    </p>
  </div>
</template>
